import PropTypes from "prop-types"
import React, { Component } from "react"
import BU from "../../lib/BuilderUtils"
import { WELL_STATUS } from "../../redux/types"
import ScrollContainer from "../builder/ScrollContainer"
import StatusSelector from "../builder/StatusSelector"
import WellStatusName from "../common/WellStatusName"
import RoundedDropDown from "../dropdown/RoundedDropDown"

const statusArr = Array.from(WELL_STATUS).map((val) => ({
  label: val[1].name,
  name: val[1].name,
  value: val[0],
  color: val[1].color,
}))

class WellDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: null,
      scrollToPos: false,
      navArr: null,
    }

    this.scrollContainer = React.createRef()
    this.scrolling = false
    this.scrollToPos = 0
    this.scrollInterval = null
    this.tick = 0
    this.mouseDown = false
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selected } = this.state
    const { data, failedStatusChange, includeAfeCo2 } = this.props

    if (failedStatusChange && failedStatusChange !== prevProps.failedStatusChange) {
      this.setState({ selected: failedStatusChange, scrollToPos: true })
    }

    if (selected !== prevState.selected) {
      const scroll = document.getElementById(selected)
      const currObjPos = scroll ? scroll.offsetTop : 0

      this.scrollToPos = currObjPos - 91

      const o = document.getElementById("Details-edit")
      if (o) {
        o.scrollTop = this.scrollToPos
      }
    }

    if (data !== prevProps.data) {
      this.setState({
        navArray:
          includeAfeCo2 && includeAfeCo2 === true
            ? [
                ...BU.getStatusArrayWithCompletion(data),
                { name: "Financial & environmental overview", type: "afe_and_co2_cost" },
                { name: "Additional scope", type: "additional_scope" },
              ]
            : [...BU.getStatusArrayWithCompletion(data), { name: "Additional scope", type: "additional_scope" }],
      })
    }
  }

  renderStatus = (key, val, closeFunction) => (
    <div
      onClick={() => this.onStatusChange(val, closeFunction)}
      className={`RoundedDropDown__Option RoundedDropDown__Option--status${val.selected ? " RoundedDropDown__Option--selected" : ""}`}
      key={`${key}-statusfilter-option`}
    >
      <span style={{ background: val.color }} className="RoundedDropDown__Option--status-color"></span>
      <span className="RoundedDropDown__Option-Value">
        <WellStatusName statusKey={val.value} />
      </span>
    </div>
  )

  onStatusChange(statusObj, closeFunction) {
    const { handleStatusChange, data } = this.props

    handleStatusChange(statusObj, data.id)
    closeFunction()
  }

  handleContentEditable = (event) => {
    const {
      target: { value },
    } = event
    const {
      handleCommentChange,
      data: { id },
    } = this.props
    handleCommentChange(value, id)
  }

  onScroll = () => {
    const { navArray } = this.state

    const { scrollTop } = document.getElementById("Details-edit")
    let newSelection = navArray[0].type

    navArray.forEach((val) => {
      const elm = document.getElementById(val.type)
      if (elm && scrollTop + 250 > elm.offsetTop) {
        newSelection = val.type
      }
    })

    this.setState({ selected: newSelection, scrollToPos: false })
  }

  componentWillUnmount = () => {
    clearInterval(this.scrollInterval)
  }

  triggerScrollTo = (to) => {
    this.scrolling = true

    this.tick = 0
    this.setState({ selected: to, scrollToPos: true })

    this.scrollInterval = setTimeout(() => {
      clearInterval(this.scrollInterval)
      this.scrolling = false
    }, 1000)
  }

  filterWells = () => {
    const { navArray } = this.state

    return navArray || []
  }

  render() {
    const {
      data,
      showExpanded,
      assets,
      blocks,
      licenses,
      rigList,
      onFieldUpdate,
      failedStatusChange,
      direction,
      activeRigTypes,
      activeWellTypes,
      includeAfeCo2,
      scenarios,
      units,
      currency,
      user,
    } = this.props
    const { selected } = this.state

    if (!data.id) {
      return null
    }
    if (!assets.data || !blocks.data || !licenses) {
      return null
    }
    if (!rigList) {
      return null
    }

    const statuses = statusArr.map((val) => ({
      name: val.name,
      value: val.value,
      color: val.color,
      selected: data.status,
    }))

    const navArr = showExpanded ? this.filterWells() : []
    const selectedWellStatus = data ? statusArr.find((a) => a.value === data.status) : null

    return (
      <>
        <div className="Details__status">
          <div className="Details__status-name">
            <div className="Details__welltitle">{data.well.name || data.well.short_name}</div>
          </div>
          <RoundedDropDown
            label={
              selectedWellStatus ? (
                <span>
                  <span className="RoundedDropDown__circle" style={{ background: selectedWellStatus.color }}></span>
                  <WellStatusName statusKey={selectedWellStatus.value} />
                </span>
              ) : (
                "Select status"
              )
            }
            hasSelectedValue={!!selectedWellStatus}
            passCloseFunction={true}
            direction={direction}
            renderoption={this.renderStatus}
            data={statuses}
          />
          <StatusSelector
            selected={selected}
            navArr={navArr}
            showExpanded={showExpanded}
            clickHandler={(type) => this.setState({ selected: type, scrollToPos: true })}
            isOccupiedSelected={data.status && data.status === "occupied"}
          />
          <div className={`Details__comment left ${!showExpanded ? " hidden" : ""}`}>
            <div className="Details__label">Well comment</div>
            <textarea
              value={data.well.comment || ""}
              className={`Details__value ${data.well.comment ? "active" : ""}`}
              onChange={this.handleContentEditable}
            />
          </div>
        </div>
        <div className={`Details__comment ${showExpanded ? " hidden" : ""}`}>
          <div className="Details__label">Well comment</div>
          <textarea
            value={data.well.comment || ""}
            className={`Details__value ${data.well.comment ? "active" : ""}`}
            onChange={this.handleContentEditable}
          />
        </div>
        <ScrollContainer
          ref={this.scrollContainer}
          onScroll={this.onScroll}
          showExpanded={showExpanded}
          onFieldUpdate={onFieldUpdate}
          data={data}
          blocks={blocks}
          rigList={rigList}
          assets={assets}
          licenses={licenses}
          failedStatusChange={failedStatusChange}
          activeRigTypes={activeRigTypes}
          activeWellTypes={activeWellTypes}
          includeAfeCo2={includeAfeCo2}
          scenarios={scenarios}
          units={units}
          user={user}
          currency={currency}
        />
      </>
    )
  }
}

WellDetails.propTypes = {
  data: PropTypes.object,
  blocks: PropTypes.object,
  assets: PropTypes.object,
  licenses: PropTypes.object,
  failedStatusChange: PropTypes.string,
  rigList: PropTypes.array,
  onFieldUpdate: PropTypes.func,
  handleStatusChange: PropTypes.func,
  handleCommentChange: PropTypes.func,
  classes: PropTypes.string,
  direction: PropTypes.string,
  showExpanded: PropTypes.bool,
  activeRigTypes: PropTypes.array,
  activeWellTypes: PropTypes.array,
  includeAfeCo2: PropTypes.bool,
  scenarios: PropTypes.object,
  units: PropTypes.string,
  currency: PropTypes.string,
  user: PropTypes.object,
}

export default WellDetails
