import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ActionCreators } from "../../../redux/actions"

class AdditionalScopeSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "",
      selectedWells: [],
    }
  }

  componentDidMount() {
    const { getWellList, additionalScopeWells } = this.props
    getWellList()

    if (additionalScopeWells) {
      this.setState({ selectedWells: additionalScopeWells })
    }
  }

  doUpdate = () => {
    const { setShowAdditionalScopeWells, onClose, fetchRigData } = this.props
    const { selectedWells } = this.state
    setShowAdditionalScopeWells(selectedWells)
    fetchRigData()
    onClose()
  }
  reset = () => {
    const { setShowAdditionalScopeWells, onClose, fetchRigData } = this.props
    setShowAdditionalScopeWells([])
    this.setState({ selectedWells: [] })
    fetchRigData()
    onClose()
  }

  filter = (event) => {
    this.setState({ filter: event.target.value })
  }

  render() {
    const { filter, selectedWells } = this.state
    const { wells, onClose } = this.props

    const itemList = filter
      ? wells.filter((v) => {
          const n = v.name + " " + v.short_name
          const exist = n.replace(/\//g, "===").toLowerCase().indexOf(filter.replace(/\//g, "===").toLowerCase()) > -1
          return exist
        })
      : wells

    return (
      <div className="content">
        <div className={`ContentList`} style={{ width: "100%", maxWidth: "none" }}>
          <div className="ContentList__HeaderWrapper">
            <h1 className="ContentList__Header">Select wells for additional scope</h1>
            <input onChange={this.filter} className={"ContentList__Search"} placeholder="Filter" />
          </div>
          <div className="ContentList__Content">
            {itemList &&
              itemList.map((b, k) => {
                const is = selectedWells.includes(b.id)
                return (
                  <div key={k} className="ContentList__ListItem">
                    <span className="ContentList__ItemTitle">{b.name}</span>
                    <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
                      {is && (
                        <button
                          onClick={() =>
                            this.setState({
                              selectedWells: selectedWells.filter((item) => item !== b.id),
                            })
                          }
                          className="ContentList__EditBtn"
                        >
                          Dont't show
                        </button>
                      )}
                      {!is && (
                        <button
                          onClick={() =>
                            this.setState({
                              selectedWells: [...this.state.selectedWells, b.id],
                            })
                          }
                          className="ContentList__EditBtn"
                        >
                          Show
                        </button>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="ContentList__Footer">
            <button onClick={this.reset} className={`ContentList__RemoveBtn show`}>
              Unselect all
            </button>
            <div>
              <button onClick={() => onClose()} className={`ContentList__RemoveBtn show`}>
                Dismiss
              </button>
              <button onClick={this.doUpdate} className={`ContentList__RemoveBtn show`}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AdditionalScopeSelection.propTypes = {
  overridenPValueWells: PropTypes.object,
  getWellList: PropTypes.func,
  setOverridePValueForWell: PropTypes.func,
  onClose: PropTypes.func,
  wells: PropTypes.array,
  fetchRigData: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    overridenPValueWells: state.schedulerData.overridenPValueWells,
    wells: state.managecontent.wells,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalScopeSelection)
