import addDays from "date-fns/addDays"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import parseISO from "date-fns/parseISO"
import PropTypes from "prop-types"
import React, { Component } from "react"

import { WELL_PLACEMENT_BOTTOM, WELL_PLACEMENT_TOP } from "../../wellConsts"

import CU from "../../lib/CalendarUtils"
import POPOVER_WELLS from "../../popoverConsts"

class DNDContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      priority: props.priority,
    }

    this.myRef = React.createRef()
    this.dragStart = 0
    this.dragging = false
  }

  componentDidUpdate() {}

  getSnapshotBeforeUpdate() {
    const { moveObject, id } = this.props
    const check = moveObject && moveObject.id !== id && moveObject.dom

    return {
      active: !!check,
    }
  }

  componentDidUpdate() {}

  onMouseDown = () => {
    this.dragStart = new Date().getTime()
  }

  onMouseUp = (e) => {
    this.dragStart = 0
    e.target.style.opacity = 1
    e.target.style.pointerEvents = "all"
  }

  onMouseMove = (e) => {
    const time = new Date().getTime()

    if (this.dragStart !== 0 && time - this.dragStart > 50 && !this.dragging) {
      this.dragging = true
      this.onDragStart(e)
    } else {
      this.dragging = false
      this.dragStart = 0
    }
  }

  onDragStart = (e) => {
    e.stopPropagation()

    const {
      setTemporaryWell,
      PVersion,
      id,
      rigId,
      estimate,
      pressure,
      rigMove,
      type: rigType,
      bounds,
      priority,
      hasContractWith,
      timespan: { from },
    } = this.props

    const dom = e.target
    const data = {
      PVersion,
      id,
      rigId,
      from,
      estimate,
      pressure,
      rigMove,
      rigType,
      hasContractWith,
      bounds,
      priority,
    }

    setTemporaryWell(dom, data)

    this.myRef.current.style.opacity = 0.2
    this.myRef.current.style.pointerEvents = "none"
  }

  getPosY = (placement) => {
    switch (placement) {
      case WELL_PLACEMENT_TOP:
        return -15
      case WELL_PLACEMENT_BOTTOM:
        return 15
      default:
        return 0
    }
  }

  getStyles = () => {
    const { dayWidth, timespan, estimateData, placement, activePVersion, rig_move: rigMove } = this.props

    const style = {}
    const useDate = estimateData[activePVersion].start_date

    const isoFrom = addDays(parseISO(useDate), rigMove ? rigMove.estimate : 0)
    const posX = differenceInCalendarDays(isoFrom, timespan.from) * dayWidth
    const posY = this.getPosY(placement)
    style.transform = `translate3d(${posX}px, ${posY}px, 0)`

    return style
  }

  getWellBasedOnMouseX = (xPos, wells) => {
    const { PVersion, dayWidth } = this.props

    let pos = 0
    let wellId = 0
    let wellData = {}

    for (let i = 0; i < wells.length; i += 1) {
      if (xPos > pos) {
        wellId = i
        wellData = wells[i]
      }

      pos += wells[i].estimateData[PVersion.value].estimate * dayWidth
    }

    return { wellId, wellData }
  }

  onSelect = (e) => {
    const { setScrollObjectPosition, openWellPopover, data } = this.props

    const well = this.getWellBasedOnMouseX(e.nativeEvent.offsetX, data.wells)
    const { wellId, wellData } = well

    const elem = document.getElementById("Harry-Plotter")
    const parentPos = elem.getBoundingClientRect()
    const { scrollTop } = elem

    const pos = this.myRef.current.getBoundingClientRect()

    const relativePos = {}

    relativePos.top = pos.top - parentPos.top + scrollTop

    const isRight = elem.offsetWidth - 160 < pos.left - parentPos.left
    const isLeft = pos.left - parentPos.left <= 3

    relativePos.left = isLeft ? 3 : pos.left - parentPos.left

    relativePos.height = pos.height
    relativePos.width = pos.width

    if (isRight) {
      relativePos.left = Math.min(pos.left - parentPos.left, elem.offsetWidth - 170)
      relativePos.isRight = true
    }

    if (this.shouldOpenPopOver(wellData)) {
      openWellPopover(POPOVER_WELLS, {
        ...data,
        additional_scope_p10: wellData.additional_scope_p10,
        additional_scope_p50: wellData.additional_scope_p50,
        additional_scope_p90: wellData.additional_scope_p90,
        pos: relativePos,
        wellClickedID: wellId,
      })

      setScrollObjectPosition(relativePos.left, data.id)
    }
  }

  shouldOpenPopOver = (well) => {
    const { filter } = this.props

    const assetId = well.well.license ? well.well.license.asset_id : 0
    const licenseId = well.well.license_id
    const name = CU.getFirstNameForWellBookings(well)
    const shortName = well.well.short_name
    const areaId = well.well.license ? well.well.license.area_id : 0

    const wellType = well.well_type

    const hasContractWith = !!well.has_contract_with
    const isHpht = well.well.pressure === "hpht"

    return !CU.shouldWellBeFilteredOut(filter, well.status, assetId, licenseId, name, shortName, hasContractWith, isHpht, areaId, wellType)
  }

  preventDragHandler = (e) => {
    e.preventDefault()
  }

  render() {
    const {
      classNames,
      styles,
      readonly,
      id,
      accessLevel,
      PVersion,
      setTemporaryWell,
      rigId,
      estimate,
      pressure,
      bounds,
      priority,
      rig_move: rigMove,
      type: rigType,
      start_date: startDate,
      hasContractWith,
    } = this.props

    const { active } = this.state

    const style = this.getStyles()

    const draggable = !active && !readonly && accessLevel >= 10

    return (
      <div
        id={id}
        draggable={draggable}
        onDragStart={this.preventDragHandler}
        onDrag={() => false}
        onClick={this.onSelect}
        data-props={JSON.stringify({
          setTemporaryWell,
          PVersion,
          id,
          rigId,
          estimate,
          pressure,
          rigMove,
          rigType,
          bounds,
          startDate,
          draggable,
          priority,
          hasContractWith,
        })}
        ref={this.myRef}
        className={`DNDC ${classNames} ${active === true ? "ignore-events" : ""}`}
        style={Object.assign({}, style, styles)}
      >
        {this.props.children}
      </div>
    )
  }
}

DNDContainer.propTypes = {
  id: PropTypes.number,
  rigId: PropTypes.number,
  classNames: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.any,
  setTemporaryWell: PropTypes.func,
  PVersion: PropTypes.object,
  activePVersion: PropTypes.string,
  timespan: PropTypes.object,
  moveObject: PropTypes.object,
  estimate: PropTypes.number,
  readonly: PropTypes.bool,
  setRef: PropTypes.any,
  isOverlapping: PropTypes.bool,
  header: PropTypes.string,
  pressure: PropTypes.string,
  rigMove: PropTypes.object,
  bounds: PropTypes.object,
  rigType: PropTypes.array,
  hasContractWith: PropTypes.number,
  data: PropTypes.any,
  zoomLevel: PropTypes.number,
  dayWidth: PropTypes.number,
  start_date: PropTypes.any,
  parent: PropTypes.bool,
  filter: PropTypes.object,
  wells: PropTypes.array,
  openWellPopover: PropTypes.func,
  setScrollObjectPosition: PropTypes.func,
  popOverData: PropTypes.object,
  darkMode: PropTypes.bool,
  rig_move: PropTypes.object,
  placement: PropTypes.string,
  type: PropTypes.array,
  accessLevel: PropTypes.number,
  priority: PropTypes.number,
  estimateData: PropTypes.object,
}

export default DNDContainer
