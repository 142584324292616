import { css } from "emotion"
import { cloneDeep, find, isEqual } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import parseISO from "date-fns/parseISO"

import differenceInDays from "date-fns/differenceInDays"
import * as types from "../../../../redux/types"
import MoveObject from "../../../dragndrop/MoveObject"
import PlacementIndicator from "../../../dragndrop/PlacementIndicator"

import { ActionCreators } from "../../../../redux/actions"
import { HPHT, WELLS } from "../../../../registerConsts"
import { WELL_PLACEMENT_BOTTOM, WELL_PLACEMENT_TOP } from "../../../../wellConsts"

import CU from "../../../../lib/CalendarUtils"

const inbetweenSlack = 2
let placement = WELL_PLACEMENT_TOP

class PlotterRow extends Component {
  constructor(props) {
    super(props)

    const {
      id,
      rig_id: toRigId,
      schedule_version_id: scheduleVersionId,
      rigData: { type, pressure },
    } = props.info

    this.state = {
      rig_instance_id: id,
      rig_id: toRigId,
      schedule_version_id: scheduleVersionId,
      entries: null,
      entriesPrioritation: [],
      suggestions: [],
      suggestionExits: false,
      suggestionInSchedule: null,
      isOver: false,
      moveObject: null,
      previewPlacementObject: null,
      ready: true,
      bookingIsColiding: false,
      rowDragable: true,
      markers: [],
      bookingDays: [],
      dragOverErrorMessage: null,
      type,
      pressure,
      dragState: {
        over: false,
        inBetween: false,
      },
    }

    this.drag = React.createRef()
  }

  componentDidMount = () => {
    const {
      info: { entries },
    } = this.props

    this.setState({ bookingDays: this.getBookingDays(entries) })
  }

  removeCreatedAt = (elm) => {
    const nElm = cloneDeep(elm)
    nElm.updated_at = 0
    return nElm
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const {
      info: { entries },
      suggestions: data,
      revision,
      dragRowID,
      moveObject,
      dropReady,
      updateRigDataIndex,
      fetchRigDataIndex,
      dayWidth,
      darkMode,
      filter,
      PVersion,
      scrollPosition,
      hiddenRigs,
    } = nextProps

    const { suggestions, dragState, rowDragable, dragOverErrorMessage } = nextState

    const updatedRow = dragRowID === this.state.rig_id
    const prevUpdatedRow = this.props.dragRowID === this.state.rig_id

    // RENDER FIRST UPDATE WHEN ENTRIES
    if (entries && !this.state.entries) {
      return true
    }

    // RENDER WHEN ENTRIES CHANGE OR REVISION OF ENTRIES UPDATES
    if (entries !== this.props.info.entries || revision !== this.props.revision || nextState.entries !== this.state.entries) {
      return true
    }

    // RENDER WHEN SUGGESTIONS UPDATES
    if (data !== this.props.suggestions || suggestions !== this.state.suggestions) {
      return true
    }

    if (scrollPosition !== this.props.scrollPosition) {
      return false
    }

    // RENDER WHEN MOVEOBJECT IS CREATED
    if (moveObject !== this.props.moveObject) {
      return true
    }

    // RENDER WHEN MOVEOBJECT IS CREATED IN STATE
    if (nextState.moveObject && !this.state.moveObject) {
      return true
    }

    // RENDER WHEN MOVEOBJECT IS CREATED IN STATE
    if (!nextState.moveObject && this.state.moveObject) {
      return true
    }

    // RENDER WHEN THE DRAG ROW IS ChANGED
    if (dragRowID !== this.props.dragRowID) {
      if (updatedRow || prevUpdatedRow) {
        return true
      }
    }

    // RENDER IF dragState IS UPDATED
    if (dragState !== this.state.dragState) {
      return true
    }

    if (dragOverErrorMessage !== this.state.dragOverErrorMessage) {
      return true
    }

    // UPDATE IF DROP READY
    if (dropReady !== this.props.dropReady) {
      return true
    }

    // DO DEEP CHECK, IF CHANGE THEN UPDATE
    // TODO Check performance for this
    if (!this.deepCheckEntries(entries, this.props.info.entries)) {
      return true
    }

    // WHEN SCHEDULER DATA IS UPDATED FORCE REDRAW OF ALL ROWS
    if (updateRigDataIndex !== this.props.updateRigDataIndex) {
      return true
    }

    if (fetchRigDataIndex !== this.props.fetchRigDataIndex) {
      return true
    }

    if (rowDragable !== this.state.rowDragable) {
      return true
    }

    if (dayWidth !== this.props.dayWidth) {
      return true
    }

    if (darkMode !== this.props.darkMode) {
      return true
    }

    if (filter !== this.props.filter) {
      return true
    }

    if (hiddenRigs !== this.props.hiddenRigs) {
      return true
    }

    if (PVersion !== this.props.PVersion) {
      return true
    }

    return false
  }

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    const {
      info: { entries },
      suggestions,
      moveObject,
      dropReady,
      revision,
      dragRowID,
      darkMode,
      updateRigDataIndex,
      fetchRigDataIndex,
      dayWidth,
      filter,
      PVersion,
    } = this.props

    // DO NOTHING UNTIL ENTRIES
    if (entries.length > 0) {
      // SET STATE ENTRIES FIRST TIME
      if (!this.state.entries) {
        // console.warn('ENTRIES READY');
        return { type: "ENTRIES", data: entries }
      }
    }

    // REDRAW WHEN SUGGESTIONS UPDATE
    if (suggestions.suggestions !== prevProps.suggestions.suggestions) {
      return { type: "SUGGESTIONS", suggestions }
    }

    // REMOVE ANY MOVEOBJECT ON NEW DATA FROM SERVER
    if (updateRigDataIndex !== prevProps.updateRigDataIndex) {
      // console.warn('REMOVE THIS FOR ALL');
      return { type: "REMOVE_MOVEOBJECT_AND_RESET_DRAGGABLE_CHECK" }
    }

    // REDRAW IF ENTRIES CHANGE
    if (PVersion !== prevProps.PVersion || entries !== prevProps.info.entries || !this.deepCheckEntries(entries, prevProps.info.entries)) {
      return { type: "ENTRIES", data: entries }
    }

    // REDRAW IF ENTRIES CHANGE
    if (entries !== prevProps.info.entries || revision !== prevProps.revision) {
      return { type: "ENTRIES", data: entries }
    }

    // REDRAW IF dayWidth CHANGE
    if (dayWidth !== prevProps.dayWidth) {
      return { type: "ENTRIES", data: entries }
    }

    // REDRAW IF dayWidth CHANGE
    if (darkMode !== prevProps.darkMode) {
      return { type: "ENTRIES", data: entries }
    }

    // REDRAW IF dayWidth CHANGE
    if (filter !== prevProps.filter) {
      return { type: "ENTRIES", data: entries }
    }

    if (prevState.entries !== this.state.entries) {
      return { type: "UPDATE_BOOKING_DAYS" }
    }

    // REDRAW IF WE CREATE AN MOVEOBJECT
    if (Object.keys(prevProps.moveObject).length === 0 && Object.keys(moveObject).length > 0) {
      if (moveObject.rigId === this.state.rig_id) {
        return {
          type: "CREATE_MOVEOBJECT",
          data: moveObject,
        }
      }

      const isd = this.checkIfDraggable(moveObject)
      return {
        type: "CREATE_MOVEOBJECT_AND_SET_IF_ROW_DRAGGABLE",
        data: null,
        rowDragable: isd.valid,
        dragOverErrorMessage: isd.errMessage,
      }
    }

    //  RENDER WHEN ROW INDEX IS UPDATED
    if (dragRowID !== prevProps.dragRowID) {
      const updatedRow = dragRowID === this.state.rig_id
      const prevUpdatedRow = prevProps.dragRowID === this.state.rig_id

      if (updatedRow) {
        return { type: "CREATE_MOVEOBJECT", data: moveObject }
      }
      if (prevUpdatedRow) {
        return { type: "REMOVE_MOVEOBJECT" }
      }
    }

    // RENDER WHEN DROP READY IS IN SYNC
    if (dropReady !== prevProps.dropReady) {
      return {
        type: "DROP_UPDATE",
        data: {
          dropReady,
        },
      }
    }

    if (fetchRigDataIndex !== prevProps.fetchRigDataIndex) {
      return {
        type: "CLEAR",
      }
    }

    return null
  }

  deepCheckEntries = (e1, e2) => {
    // CHECK for entries
    const n1 = cloneDeep(e1)
    const n2 = cloneDeep(e2)

    let i = 0
    for (i = 0; i < n1.length; i += 1) {
      delete n1[i].updated_at
      delete n1[i].wells
      delete n1[i].revision
    }
    for (i = 0; i < n2.length; i += 1) {
      delete n2[i].updated_at
      delete n2[i].wells
      delete n2[i].revision
    }

    const ie = isEqual(n1, n2)
    return ie
  }

  getBookings = (snapshot, type) => {
    const {
      info: { schedule_version_id: scheduleVersionId, id: rigInstenceID, rig_id: rigId, type: rigType, rigData },
      PVersion,
      timespan,
      dayWidth,
      zoomLevel,
      darkMode,
      filter,
      popOverData,
      openWellPopover,
      setScrollObjectPosition,
      revision,
      readonly,
      additionalScopeWells,
    } = this.props

    const propValues = {
      PVersion,
      timespan,
      dayWidth,
      zoomLevel,
      darkMode,
      filter,
      popOverData,
      openWellPopover,
      setScrollObjectPosition,
      scheduleVersionId,
      rigInstenceID,
      rigId,
      rigType,
      revision,
      readonly,
      additionalScopeWells,
      ...rigData,
    }

    const data = type === "ENTRIES" ? snapshot.data : find(snapshot.suggestions.suggestions, (o) => o.rig_id === rigId).entries

    const newEntries = data.map((val, key) => {
      const { booking_type: bookingType, id, rig_placement_id: rigPlacementId, schedule_id: scheduleId } = val

      const objectValues = {
        bookingType,
        id,
        rigPlacementId,
        scheduleId,
      }

      switch (val.booking_type) {
        case types.WELLS:
          return CU.getWellSequence(propValues, objectValues, val, key, readonly)
        case types.RIG_MOVE:
          return CU.getRigMove(propValues, objectValues, val, key)
        case types.SUGGESTION:
          return CU.getSuggestion(propValues, objectValues, val, key)
        case types.FREE_TIME:
          return CU.getFreeTime(propValues, objectValues, val, key)
        case types.NO_CONTRACT:
          return CU.noContract(propValues, objectValues, val, key)
        case types.CONTRACT_OPTION:
          return CU.contractOption(propValues, objectValues, val, key)
        default:
          console.log("no support for type", val.booking_type)
          return (
            <div className="NoSupportWell" key={Math.random()}>
              NO support for this bookingType
            </div>
          )
      }
    })

    return newEntries
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const {
      info: { rig_id: rigId, entries },
    } = this.props

    if (snapshot) {
      // IF ENTRIES UPDATES CREATE NEW ENTRIES
      if (snapshot.type === "ENTRIES") {
        const newEntries = this.getBookings(snapshot, snapshot.type)
        this.setState({
          entries: newEntries,
        })
      }

      if (snapshot.type === "UPDATE_BOOKING_DAYS") {
        this.setState({
          bookingDays: this.getBookingDays(entries),
        })
      }
      // IF SUGGESTIONS UPDATES DISPLAY NEW SUGGESTIONS
      if (snapshot.type === "SUGGESTIONS") {
        const suggestions =
          snapshot && snapshot.suggestions && snapshot.suggestions.suggestions
            ? snapshot.suggestions.suggestions.filter((rig) => rig.rig_id === rigId)[0]
            : null

        let newSuggestions = null

        if (suggestions) {
          newSuggestions = this.getBookings(snapshot, snapshot.type)
        }

        this.setState({
          suggestions: newSuggestions,
        })
      }

      // IF MOVEOBJECT IS CREATED IN REDUX STATE
      if (snapshot.type === "CREATE_MOVEOBJECT_AND_SET_IF_ROW_DRAGGABLE") {
        this.setState({
          moveObject: this.createElementFromETarget(snapshot.data),
          rowDragable: snapshot.rowDragable,
          dragOverErrorMessage: snapshot.dragOverErrorMessage,
        })
      }

      // IF MOVEOBJECT IS CREATED IN REDUX STATE
      if (snapshot.type === "CREATE_MOVEOBJECT") {
        this.setState({
          moveObject: this.createElementFromETarget(snapshot.data),
          markers: this.getmarkers(),
        })
      }

      if (snapshot.type === "REMOVE_MOVEOBJECT") {
        this.setState({
          moveObject: null,
        })
      }

      if (snapshot.type === "REMOVE_MOVEOBJECT_AND_RESET_DRAGGABLE_CHECK") {
        this.setState({
          moveObject: null,
          markers: [],
          rowDragable: true,
        })
      }

      if (snapshot.type === "CLEAR") {
        this.setState({
          moveObject: null,
          markers: [],
          rowDragable: true,
        })
      }

      // IF DROP READY, UPDATE CCS
      if (snapshot.type === "DROP_UPDATE") {
        this.updateDropNotification(snapshot.data.dropReady, this.state.dragState.inBetween)
      }
    }
  }

  // Disable this check for now, as requested by Aker
  checkIfDraggable = (data) => {
    // if (data && data.hasContractWith) {
    //   if (data.hasContractWith !== this.state.rig_id) {
    //     return {
    //       valid: false,
    //       errMessage: "The well has contract with another rig",
    //     }
    //   }
    // }

    return {
      valid: true,
      errMessage: null,
    }
    // if (data) {
    //   // IF THIS ROW, THEN ALWAYS ALOW TO DRAG FROM IT
    //   if (data.rigId === this.state.rig_id) {
    //     return {
    //       valid: true,
    //       errMessage: null,
    //     }
    //   }

    //   // IF THERE IS A CONTRACT, HIDE THEM THAT IS NOT IN CONTRACT
    //   if (data.hasContractWith) {
    //     if (data.hasContractWith === this.state.rig_id) {
    //       return {
    //         valid: true,
    //         errMessage: null,
    //       }
    //     }

    //     return {
    //       valid: false,
    //       errMessage: "The well has contract with another rig",
    //     }
    //   }

    //   // IF TYPE OF RIG NEEDED IS THIS RIG
    //   if (data.rigType && (data.rigType.includes(this.state.type) || data.rigType === this.state.type)) {
    //     if (data.pressure) {
    //       const hasP = this.rigHasSupportForPressureValue(data.pressure, this.state.pressure)
    //       return {
    //         valid: hasP,
    //         errMessage: !hasP ? "This rig doesn't support the pressure of the well" : null,
    //       }
    //     }
    //     return {
    //       valid: true,
    //       errMessage: null,
    //     }
    //   }

    //   // THE WELL DOESNT HAVE ANY CONTRAINS
    //   if (!data.rigType && !data.hasContractWith) {
    //     return {
    //       valid: true,
    //       errMessage: null,
    //     }
    //   }
    // }

    // return {
    //   valid: false,
    //   errMessage: "This is the wrong rig type",
    // }
  }

  rigHasSupportForPressureValue = (pressure, rigPressure) => pressure <= rigPressure

  rigHasSupportForPressure = (pressure) => {
    switch (pressure) {
      case HPHT:
        return parseInt(this.state.pressure, 0) > 5000
      default:
        return false
    }
  }

  updateDropNotification = (dropReady, inBetween) => {
    const node = this.drag

    if (node.current) {
      // TODO: optimize this
      if (dropReady === true && inBetween) {
        node.current.classList.add("dropReady")
      } else {
        node.current.classList.remove("dropReady")
      }
    }
  }

  getBookingDays = (entries) => {
    const { timespan } = this.props
    const days = []

    const el = entries.length
    let val
    let day

    for (let i = 0; i < el; i += 1) {
      val = entries[i]

      if (val.booking_type === WELLS) {
        day = this.dayObject(val, timespan.from)
        days.push(day)
      }
    }

    return days
  }

  dayObject = (elm, calendarStartDate) => {
    const { PVersion } = this.props

    const { estimate, estimateData, start_date: startDate, calculated_start_date: calculatedStartDate } = elm

    const sd = calculatedStartDate || estimateData[PVersion.value].start_date

    const isoFrom = parseISO(sd)

    const dayFromStartOfCalendar = differenceInDays(isoFrom, calendarStartDate)

    const est = dayFromStartOfCalendar

    const endDayFromStartOfCalendar = est + estimateData[PVersion.value].estimate

    return {
      id: elm.id,
      type: elm.booking_type,
      startDate,
      estimate,
      dayFromStartOfCalendar,
      endDayFromStartOfCalendar,
    }
  }

  createElementFromETarget = (object) => {
    if (!object || Object.keys(object).length === 0) return null

    const classes = `${object.dom.className} ignore-events drag`
    const { style } = object.dom

    const newStyle = {
      position: "relative",
      backgroundColor: style.backgroundColor,
      borderBottomColor: style.borderBottomColor,
      borderColor: style.borderColor,
      borderTopColor: style.borderTopColor,
      width: style.width,
      zIndex: "100",
    }

    return (
      <div
        ref={this.drag}
        className={classes}
        style={newStyle}
        dangerouslySetInnerHTML={{
          __html: object.dom.innerHTML,
        }}
      ></div>
    )
  }

  onMouseEnter = (e) => {
    e.stopPropagation()

    const { rig_id: rigID } = this.state
    const { setDragRow } = this.props

    setDragRow(rigID)
  }

  onMouseLeave = (e) => {
    e.stopPropagation()

    const { setDragRow } = this.props

    if (e.target.classList.contains("Row")) {
      setDragRow(null)
    }
  }

  checkIfOver = (a, b) => (a.end >= b.start && a.end <= b.end) || (a.start >= b.start && a.start <= b.end)

  checkForOverlap = (xPos) => {
    const { bookingDays, dragState } = this.state
    const { timespan, dayWidth, moveObject } = this.props

    const dState = {
      over: false,
      inBetween: null,
    }

    if (!Object.keys(moveObject).length) return
    const checkDateFirst = CU.getNewDate(timespan.from, xPos, dayWidth)

    const checkDateLast = CU.getLastDate(checkDateFirst, moveObject.estimate)
    const dayFromStartOfCalendar = differenceInDays(checkDateFirst, timespan.from)
    const endDayFromStartOfCalendar = differenceInDays(checkDateLast, timespan.from)

    const el = bookingDays.length || 0
    let val

    for (let i = 0; i < el; i += 1) {
      val = bookingDays[i]

      if (val.id !== moveObject.id) {
        if (this.checkIfInbetween(dayFromStartOfCalendar, val.endDayFromStartOfCalendar)) {
          dState.inBetween = {
            move: moveObject.id,
            moveBefore: val.id,
          }
        }

        if (
          this.checkIfOver(
            {
              start: dayFromStartOfCalendar,
              end: endDayFromStartOfCalendar,
            },
            {
              start: val.dayFromStartOfCalendar,
              end: val.endDayFromStartOfCalendar,
            }
          )
        ) {
          dState.over = true
        }
      }
    }

    if (!(dState.over === dragState.over && dState.inBetween === dragState.inBetween)) {
      this.setState({ dragState: dState })
    }
  }

  checkIfInbetween = (xPos, objectPos) => xPos <= objectPos && xPos >= objectPos - inbetweenSlack / 2

  getInbetweenMarkup = (val, dayWidth) => {
    const sStyle = {
      transform: `translate3d(${(val.endDayFromStartOfCalendar - inbetweenSlack / 2) * dayWidth}px, -50%, 0)`,
      width: `${inbetweenSlack * dayWidth}px`,
    }

    return <PlacementIndicator key={val.id} {...val} sStyle={sStyle} dayWidth={dayWidth} />
  }

  isOverlapping = (elm1, elm2) => {
    const checkDateFirst = new Date(elm1.start_date)
    const checkDateLast = CU.getLastDate(checkDateFirst, elm1.estimate)

    const withFromDate = new Date(elm2.start_date)
    const withToDate = CU.getLastDate(withFromDate, elm2.estimate)

    let check = false

    check = areIntervalsOverlapping(
      {
        start: checkDateFirst,
        end: checkDateLast,
      },
      {
        start: withFromDate,
        end: withToDate,
      }
    )

    return check
  }

  getPlacement = () => {
    if (placement === WELL_PLACEMENT_TOP) {
      placement = WELL_PLACEMENT_BOTTOM
      return WELL_PLACEMENT_TOP
    }

    placement = WELL_PLACEMENT_TOP
    return WELL_PLACEMENT_BOTTOM
  }

  getmarkers = () => {
    const {
      moveObject: { bounds },
      timespan,
      dayWidth,
    } = this.props

    const markers = []

    if (bounds) {
      if (bounds.startDateIsHard) {
        const startPos = CU.getPositionBasedOnDate(timespan.from, bounds.earliestStartDate, dayWidth)
        const sStyle = {
          transform: `translate3d(${startPos}px, 0, 0)`,
        }

        markers.push(
          <span key={"marker-start"} className="Date_Marker Date_Marker_Start--Hard" style={sStyle}>
            |
          </span>
        )
      }

      if (bounds.endDateIsHard) {
        const endPos = CU.getPositionBasedOnDate(timespan.from, bounds.lastPossibleEndDate, dayWidth)
        const eStyle = {
          transform: `translate3d(${endPos}px, 0, 0)`,
        }
        markers.push(
          <span key={"marker-end"} className="Date_Marker Date_Marker_End--Hard" style={eStyle}>
            |
          </span>
        )
      }
    }

    return markers
  }

  render = () => {
    const {
      moveObject,
      previewPlacementObject,
      dragState,
      entries,
      suggestions,
      bookingDays,
      rowDragable,
      markers,
      dragOverErrorMessage,
      rig_id: rigId,
    } = this.state

    const { dayWidth, scrollPosition, hiddenRigs } = this.props

    const { dragRowID } = this.props

    const thisRow = dragRowID && dragRowID === rigId && moveObject

    const inBetween = dragState.inBetween && thisRow ? "isInBetween" : ""

    const isOver = dragState.over && thisRow ? "isOver" : ""

    const dragEnabled = rowDragable === false ? "removeDragOver" : ""

    const mo = thisRow ? <MoveObject>{moveObject}</MoveObject> : null

    const a = mo ? "active" : ""

    const cn = css`
      &:after {
        transform: translate3d(${scrollPosition.value}px, 0, 0);
      }
    `

    const hidden = hiddenRigs.includes(rigId)

    return (
      <div
        data-err={dragOverErrorMessage}
        data-left={300}
        className={`Row Plotter--Row ${a} ${isOver} ${inBetween} ${dragEnabled} ${cn}`}
        onMouseEnter={(e) => this.onMouseEnter(e)}
        onMouseLeave={(e) => this.onMouseLeave(e)}
        data-props={JSON.stringify({
          schedule_version_id: this.state.schedule_version_id,
          rig_id: this.state.rig_id,
          dragState: this.state.dragState,
          dropReady: this.state.dragState,
        })}
        style={hidden ? { ...{ height: 20, overflow: "hidden" } } : {}}
      >
        {rowDragable && moveObject ? markers : null}
        {hidden ? null : entries}
        {suggestions}
        {mo}
        {previewPlacementObject}

        {bookingDays && moveObject ? bookingDays.map((val) => this.getInbetweenMarkup(val, dayWidth)) : null}
      </div>
    )
  }
}

PlotterRow.propTypes = {
  info: PropTypes.object,
  moveObject: PropTypes.object,
  readonly: PropTypes.bool,
  isOver: PropTypes.bool,
  darkMode: PropTypes.bool,
  dropReady: PropTypes.bool,
  setDragRow: PropTypes.func,
  timespan: PropTypes.object,
  PVersion: PropTypes.object,
  revision: PropTypes.string,
  suggestions: PropTypes.object,
  filter: PropTypes.object,
  dayWidth: PropTypes.number,
  dragRowID: PropTypes.number,
  fetchRigDataIndex: PropTypes.number,
  updateRigDataIndex: PropTypes.number,
  zoomLevel: PropTypes.number,
  openWellPopover: PropTypes.func,
  setScrollObjectPosition: PropTypes.func,
  popOverData: PropTypes.object,
  scrollPosition: PropTypes.object,
  additionalScopeWells: PropTypes.array,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    updateRigDataIndex: state.schedulerData.updateRigDataIndex,
    revision: state.schedulerData.schedulerData.data.revision,
    readonly: state.schedulerData.schedulerData.data.readonly,
    moveObject: state.schedulerData.moveObject,
    fetchRigDataIndex: state.schedulerData.fetchRigDataIndex,
    dragRowID: state.schedulerData.dragRowID,
    timespan: state.calendar.timespan,
    dayWidth: state.calendar.dayWidth,
    PVersion: state.calendar.PVersion,
    suggestions: state.requestData.suggestions,
    dropReady: state.schedulerData.dropReady,
    zoomLevel: state.calendar.zoomLevel,
    darkMode: state.calendar.darkMode,
    filter: state.filters.filter,
    xPos: state.schedulerData.xPos,
    scrollPosition: state.calendar.scrollPosition,
    hiddenRigs: state.calendar.hiddenRigs,
    additionalScopeWells: state.schedulerData.additionalScopeWells,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlotterRow)
