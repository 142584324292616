import { JsonEditor as Editor } from "jsoneditor-react"
import "jsoneditor-react/es/editor.min.css"
import "jsoneditor/src/scss/jsoneditor.scss"
import { cloneDeep, uniqBy } from "lodash"
import PropTypes from "prop-types"
import React, { Component } from "react"
import Select from "react-select"
import AsyncSelect from "react-select/async"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PanelInputSelection from "../../../components/panel/PanelInputSelection"
import { AVAILABLE_APP_SETTINGS } from "../../../lib/AppSettingsUtils"
import { ActionCreators } from "../../../redux/actions"

const defaultCurrencies = [
  {
    value: "nok",
    name: "Norske kroner",
  },
  {
    value: "usd",
    name: "United States dollar",
  },
]

class EditAppSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imagePreview: null,
      editItem: null,
    }
  }

  componentDidMount() {
    this.setState({
      editItem: this.props.editItem,
      imagePreview: this.props.editItem.value || "",
    })
  }

  handleInputChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[event.target.name] = event.target.value
    this.setState({ editItem })
  }

  handleRigTypeChange = (rigTypes) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem["value"] = rigTypes.map((e) => e.value).join(",")
    this.setState({ editItem })
  }

  handleUnitTypeChange = (unitType) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem["value"] = unitType.value
    this.setState({ editItem })
  }

  handleWellTypeChange = (wellTypes) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem["value"] = wellTypes.map((e) => e.value).join(",")
    this.setState({ editItem })
  }

  handleSelectChange = (key, value) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem[key] = value && value ? value.value : null
    this.setState({ editItem })
  }

  handleFieldsChange = (value) => {
    const editItem = cloneDeep(this.state.editItem)
    editItem["value"] = JSON.stringify(value)
    this.setState({ editItem })
  }
  handleScenarioFieldChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)

    const i = { ...editItem.value }
    i[event.target.name] = event.target.value
    editItem["value"] = i

    this.setState({ editItem })
  }
  handlewellStatusMappingFieldChange = (event) => {
    const editItem = cloneDeep(this.state.editItem)

    const values = editItem.value ? JSON.parse(editItem.value) : {}
    values[event.target.name] = event.target.value
    editItem["value"] = JSON.stringify(values)
    this.setState({ editItem })
  }

  closeModal = () => {
    const { clearSingleItemEdit } = this.props
    clearSingleItemEdit()
  }

  handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.handleEditFormSubmit(event)
    }
  }

  handleEditFormSubmit = (event) => {
    const { handleEditFormSubmit } = this.props
    const { editItem } = this.state

    event.preventDefault()
    event.stopPropagation()

    handleEditFormSubmit(editItem)
  }

  handlePhotoUpload = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]

    if (reader !== undefined && file !== undefined) {
      reader.onload = (readerEvt) => {
        let binaryString = readerEvt.target.result

        const editItem = cloneDeep(this.state.editItem)
        editItem["value"] = binaryString
        this.setState({ editItem })
      }

      reader.onloadend = () => {
        this.setState({
          imagePreview: reader.result,
        })
      }
      reader.readAsDataURL(file)
    }
  }

  loadCurrencies = async (inputValue, callback) => {
    const searchReq = await fetch(`https://restcountries.com/v3.1/currency/${inputValue}`)
    const searchRes = await searchReq.json()
    const ret = []
    searchRes.forEach((e) => {
      Object.entries(e.currencies).forEach((c) => {
        ret.push({ name: c[1].name, value: c[0] })
      })
    })
    const rr = uniqBy(ret, "code")
    callback(rr)
  }

  renderValueInput = () => {
    const { editItem, imagePreview } = this.state

    switch (editItem.key) {
      case "logo":
        return (
          <>
            <label className="ModalForm__Label">Select logo</label>
            <input type="file" name={"value"} id="file" accept=".jpef, .png, .jpg" onChange={this.handlePhotoUpload} src={imagePreview} />
            {imagePreview !== "" && <>{imagePreview && <img style={{ display: "block", marginTop: 10 }} src={imagePreview} />}</>}
          </>
        )
      case "validEmails":
        return (
          <>
            <span style={{ display: "block", margin: "10px 0", fontStyle: "italic" }}>
              Type valid emails or domains that should be able to login,
              <br /> separated by a comma.
            </span>
            <input
              type={"text"}
              onKeyDown={this.handleEnterPress}
              value={editItem.value || ""}
              name={"value"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="agr-software.com,user@gmail.com"
            />
          </>
        )
      case "additionalLegendInfo":
        return (
          <>
            <textarea
              rows={4}
              value={editItem.value || ""}
              name={"value"}
              onChange={this.handleInputChange}
              className="ModalForm__Input"
              placeholder="Write a text here..."
            />
          </>
        )
      case "includeAfeCo2":
        return (
          <>
            <PanelInputSelection
              value={editItem.value && editItem.value === "1" ? { value: "1", label: "Yes" } : { value: "0", label: "No" }}
              options={[
                { value: "1", label: "Yes" },
                { value: "0", label: "No" },
              ]}
              placeholder="Yes or no"
              isMulti={false}
              optionLabel={"label"}
              onChange={(value) => this.handleSelectChange("value", value)}
            />
          </>
        )
      case "currency":
        return (
          <>
            <AsyncSelect
              defaultOptions={defaultCurrencies}
              closeMenuOnSelect={true}
              isClearable={true}
              placeholder="Search or select currency"
              className="White__Dropdown"
              getOptionLabel={(option) => option.value}
              value={editItem.value ? { value: editItem.value } : null}
              classNamePrefix="Select__Dropdown__Elm"
              loadOptions={this.loadCurrencies}
              onChange={(value) => this.handleSelectChange("value", value)}
            />
          </>
        )
      case "units":
        const options = [
          { value: "metric", label: "Metric units" },
          { value: "imperial", label: "Imperial units" },
        ]
        return (
          <Select
            closeMenuOnSelect={true}
            name={"key"}
            getOptionLabel={(option) => option.label}
            onChange={(unit) => this.handleUnitTypeChange(unit)}
            className="White__Dropdown"
            classNamePrefix="Select__Dropdown__Elm"
            placeholder="Select units"
            value={editItem.value ? options.find((e) => e.value === editItem.value) : null}
            options={options}
          />
        )
      case "activeRigTypes":
        return (
          <>
            <PanelInputSelection
              value={
                editItem.value.split(",").filter((e) => e !== "").length > 0 ? editItem.value.split(",").map((e) => ({ value: e })) : []
              }
              options={[{ value: "jack-up" }, { value: "semi-sub" }, { value: "platform" }, { value: "land_rig" }, { value: "drillship" }]}
              placeholder="Select active rig types"
              isMulti={true}
              optionLabel={"value"}
              onChange={(rigtype) => this.handleRigTypeChange(rigtype)}
            />
          </>
        )
      case "activeWellTypes":
        return (
          <>
            <PanelInputSelection
              value={
                editItem.value.split(",").filter((e) => e !== "").length > 0 ? editItem.value.split(",").map((e) => ({ value: e })) : []
              }
              style={{ width: "100%" }}
              options={[
                { value: "development" },
                { value: "exploration" },
                { value: "appraisal" },
                { value: "injection" },
                { value: "multilateral" },
                { value: "plug_abandonment" },
                { value: "intervention" },
                { value: "geothermal" },
                { value: "water" },
                { value: "additional_scope" },
              ]}
              placeholder="Select active well types"
              isMulti={true}
              optionLabel={"value"}
              onChange={(welltype) => this.handleWellTypeChange(welltype)}
            />
          </>
        )
      case "fields":
        return (
          <>
            <span style={{ display: "block", margin: "10px 0", fontStyle: "italic" }}>Select which fields that should be used.</span>
            <Editor value={editItem.value ? JSON.parse(editItem.value) : null} onChange={this.handleFieldsChange} />
          </>
        )
      case "wellStatusMapping":
        const values = editItem.value ? JSON.parse(editItem.value) : {}
        return (
          <div className="ModalForm__WellStatusMappings">
            <div className="ModalForm__WellStatusMapping">
              <label>1 (conceptional)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.conceptional ? values.conceptional : ""}
                name={"conceptional"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Conceptional"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>1 (Plan & select)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.plan_and_select ? values.plan_and_select : ""}
                name={"plan_and_select"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Plan & select"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>3 (Engineering & design)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.engineering_and_design ? values.engineering_and_design : ""}
                name={"engineering_and_design"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Engineering & design"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>4 (Plan for execution)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.plan_for_execution ? values.plan_for_execution : ""}
                name={"plan_for_execution"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Plan for execution"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>5 (Execution)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.execution ? values.execution : ""}
                name={"execution"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Execution"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>6 (Completed)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.completed ? values.completed : ""}
                name={"completed"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Completed"
              />
            </div>
            <div className="ModalForm__WellStatusMapping">
              <label>7 (Occupied)</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={values.occupied ? values.occupied : ""}
                name={"occupied"}
                onChange={this.handlewellStatusMappingFieldChange}
                className="ModalForm__Input"
                placeholder="Occupied"
              />
            </div>
          </div>
        )
      case "scenarios":
        return (
          <div className="ModalForm__Scenarios">
            <div className="ModalForm__Scenario">
              <label>MIN</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={editItem.value ? editItem.value.p10 : ""}
                name={"p10"}
                onChange={this.handleScenarioFieldChange}
                className="ModalForm__Input"
                placeholder="Min"
              />
            </div>
            <div className="ModalForm__Scenario">
              <label>ML</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={editItem.value ? editItem.value.p50 : ""}
                name={"p50"}
                onChange={this.handleScenarioFieldChange}
                className="ModalForm__Input"
                placeholder="ML"
              />
            </div>
            <div className="ModalForm__Scenario">
              <label>MAX</label>
              <input
                type={"text"}
                onKeyDown={this.handleEnterPress}
                value={editItem.value ? editItem.value.p90 : ""}
                name={"p90"}
                onChange={this.handleScenarioFieldChange}
                className="ModalForm__Input"
                placeholder="Max"
              />
            </div>
          </div>
        )
      default:
        return (
          <input
            type={"text"}
            onKeyDown={this.handleEnterPress}
            value={editItem.value || ""}
            name={"value"}
            onChange={this.handleInputChange}
            className="ModalForm__Input"
            placeholder="value"
          />
        )
    }
  }

  render() {
    const {
      editItem: { key },
      settings,
    } = this.props
    const { editItem } = this.state

    if (!editItem) {
      return null
    }

    const options = AVAILABLE_APP_SETTINGS.filter((a) => settings.findIndex((e) => e.key === a.value) === -1)

    return (
      <form style={{ minHeight: 500 }} className="ReactModal__Wrapper" onSubmit={(event) => this.handleEditFormSubmit(event)}>
        <div style={{ justifyContent: "flex-start" }} className="ReactModal__Form ModalForm ModalForm__AppSettings">
          <h2 className="ReactModal__FormHeader">{key ? `Edit ${AVAILABLE_APP_SETTINGS.find((e) => e.value === key)?.label}` : "Add"}</h2>
          <div className="ModalForm__Part">
            {!key && <label className="ModalForm__Label">Select the setting you want to add</label>}
            {!key && (
              <Select
                key={editItem.key}
                closeMenuOnSelect={true}
                isDisabled={!!editItem.id}
                className="White__Dropdown"
                value={editItem.key ? [AVAILABLE_APP_SETTINGS.find((e) => e.value === editItem.key)] : null}
                getOptionLabel={(option) => option.label}
                classNamePrefix="Select__Dropdown__Elm"
                onChange={(value) => this.handleSelectChange("key", value)}
                name={"key"}
                options={options}
              />
            )}
          </div>
          {editItem.key && <div className="ModalForm__Part">{this.renderValueInput()}</div>}
        </div>
        <div className="ReactModal__Buttons">
          <button type="button" onClick={this.closeModal} className="ReactModal__Button">
            Cancel
          </button>
          <button type="submit" className={"ReactModal__Button ReactModal__Button--primary"}>
            {"Save"}
          </button>
        </div>
      </form>
    )
  }
}

EditAppSettings.propTypes = {
  editItem: PropTypes.object,
  clearSingleItemEdit: PropTypes.func,
  handleEditFormSubmit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    editItem: state.managecontent.editItem,
    settings: state.managecontent.settings,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAppSettings)
